

.category-image {
  width: 100%;
  height: 200px;
  border-radius: 5px;
}



