.register-container {
  background-color: white;
  padding: 20px;
  max-width: 400px;
  margin: 90px auto;
  height:600px;
  border-radius: 8px;
}

.header {
  text-align: center;
}

.inputs {
  margin-top: 40px;
}


  


.input input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 20px;
}

.error {
  color: red;
  margin-top: 5px;
}

.text-center {
  text-align: center;
  margin-top: 40px;
}

.submit-container {
  text-align: center;
  margin-top: 20px;

}

.submit-container button{
  background: coral;
  border:none;
  border-radius: 20px;
  height:50px;
  width:120px;
  color:white;
}

.input {
  display: flex;
  align-items: center; 
  margin-bottom: 10px;
}

.input img {
  width: 20px;
  height: 20px;
  margin-right: 10px; 
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .register-container {
    width: 90%;
    margin-top: 20px;
  }
  .input {
    width: 100%;
  }
}

